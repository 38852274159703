.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2 {
  background: white;
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
  padding-bottom: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  padding-top: 20px;
}

.menu ul {
  max-width: 400px;
  list-style: none;
}

.menu ul li {
  background-color: #eee;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.menu ul li.active {
  font-weight: bold;
}

.row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.col {
  flex: 0.5;
  padding-top: 15px;
}

.col.left {
  flex: 1.5;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
  word-break: break-word;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
